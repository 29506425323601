export const isNotEmptyArray = (arr: any): boolean =>
  arr && Array.isArray(arr) && Boolean(arr.length);

export const isEmptyArray = (arr: any): boolean => arr && Array.isArray(arr) && !arr.length;

export const arrayToMatrix = (arr: any[], amount: number): [any][] =>
  arr.reduce(
    (acc: any[], el: any, index: number) =>
      (index % amount === 0 ? acc.push([el]) : acc[acc.length - 1].push(el)) && acc,
    []
  );
