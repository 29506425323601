import React, { FC, ReactElement, useRef } from 'react';
import { graphql } from 'gatsby';
import FocusTrap from 'focus-trap-react';

import useClickOutside from 'hooks/useClickOutside';
import { parseButtonComposition } from 'utils/parsers';

import Button from 'common/Button';
import IconCustom from 'common/IconCustom';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Title from 'common/Title';

import { IPropsRedirectionPopup } from './model';
import './RedirectionPopup.scss';

const RedirectionPopup: FC<IPropsRedirectionPopup> = ({
  redirectionPopup,
  closeAriaLabel,
  handlePopupVisibility,
  redirectionData,
}): ReactElement | null => {
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, handlePopupVisibility);

  const {
    properties: { title, description, ctaButton },
  } = redirectionPopup[0];

  const ctaButtonData = parseButtonComposition(ctaButton);

  return (
    <div data-test="RedirectionPopup" className="redirection-popup">
      <div className="redirection-popup__cover" />
      <FocusTrap>
        <div role="dialog" className="redirection-popup__content" ref={wrapperRef}>
          <div className="redirection-popup__holder">
            <Button
              ariaLabel={closeAriaLabel}
              clickHandler={handlePopupVisibility}
              className="redirection-popup__close"
              textAlign="center"
            >
              <IconCustom icon="cross" />
            </Button>
            {title ? (
              <Title dataTestAttr="RedirectionPopupTitle" className="redirection-popup__title">
                {title}
              </Title>
            ) : null}
            {description ? (
              <DangerouslySetInnerHtml
                className="redirection-popup__description"
                html={description}
              />
            ) : null}

            {ctaButtonData ? (
              <div className="redirection-popup__cta-btn-wrapper">
                <Button
                  link={redirectionData.link}
                  ariaLabel={ctaButtonData.ariaLabel}
                  className="cta-btn redirection-popup__cta-btn"
                  textAlign="center"
                  target={redirectionData.target}
                >
                  {ctaButtonData.label}
                  <span className="icon-arrow-long redirection-popup__cta-btn-arrow" />
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </FocusTrap>
    </div>
  );
};

export const query = graphql`
  fragment FragmentRedirectionPopup on TRedirectionPopupProperties {
    properties {
      ctaButton {
        properties {
          ariaLabel
          label
        }
      }
      description
      title
    }
  }
`;

export default RedirectionPopup;
