const slidesAmountFunc = (isDesktop: boolean, windowWidth: number) => {
  let slidesAmount: number;

  if (isDesktop) {
    slidesAmount = windowWidth < 1460 ? 3 : 4;

    return slidesAmount;
  }

  slidesAmount = windowWidth < 490 ? 1 : windowWidth < 730 ? 2 : windowWidth < 970 ? 3 : 4;

  return slidesAmount;
};

export default {
  slidesAmountFunc,
};
