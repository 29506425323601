import React, { FC, useState, useCallback, useEffect, useMemo, ChangeEvent } from 'react';

import IconCustom from 'common/IconCustom';
import GatsbyImage from 'common/GatsbyImage';
import WhereToBuyRadioButtonGroup from 'components/WhereToBuyRadioButtonGroup';
import { Container } from 'layout';

import { IPropsWhereToBuyMap } from './models';
import './WhereToBuyMap.scss';
import './ShadowWhereToBuyMap.scss';

interface IPropsCoordination {
  latitude: number;
  longitude: number;
}

const WhereToBuyMap: FC<IPropsWhereToBuyMap> = ({
  map,
  searchTitle,
  headerTitle,
  brandSelector,
  isDesktop,
}) => {
  const {
    properties: {
      altLabel,
      geolocationLabel,
      title,
      subTitle,
      image,
      mobileImage,
      mapLink,
      searchAriaLabel,
      crossIconAriaLabel,
      prefix,
    },
  } = map[0];
  const {
    properties: { availability },
  } = headerTitle[0];
  const [inputValue, setInputValue] = useState<string>('');
  const [coordination, setCoordination] = useState<IPropsCoordination | null>(null);
  const [radioButtonValue, setRadioButtonValue] = useState<string | null>(null);
  const [prefixValue, setPrefixValue] = useState<string | null>('');

  const typeInputValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value),
    []
  );

  const handlerChangeRadioButtonValue = useCallback(
    (prefixCard: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setRadioButtonValue(e.target.value);
      setPrefixValue(prefixCard);
    },
    []
  );

  const handlerRemoveInputValue = useCallback(() => setInputValue(''), []);

  useEffect(() => {
    const geo = navigator.geolocation;
    if (geo) {
      geo.getCurrentPosition((position) => {
        setCoordination({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }

    if (prefix) {
      setPrefixValue(prefix);
    }

    if (brandSelector) {
      brandSelector?.properties.brand.forEach(
        ({ properties: { defaultValue, radioButton, prefix: prefixCard } }) => {
          if (defaultValue === '1' && radioButton?.[0].properties?.inputValue) {
            setRadioButtonValue(radioButton[0].properties.inputValue);
            setPrefixValue(prefixCard);
          }
        }
      );
    }
  }, []);

  const coordinates = useMemo(
    () => (coordination ? `/@${coordination.latitude},${coordination.longitude}` : ''),
    [coordination]
  );
  const searchValue = useMemo(
    () => (inputValue.trim().length >= 1 ? inputValue : ''),
    [inputValue]
  );

  return (
    <div className="where-to-buy-map__wrapper" data-test="WhereToBuyMap">
      <Container fullWidth={Boolean(isDesktop)} className="where-to-buy-map__container">
        <form
          className="where-to-buy-map__form"
          target="_blank"
          action={`${mapLink}${prefixValue}+${searchValue}${coordinates}`}
        >
          <h2 className="where-to-buy-map__title">{title}</h2>
          <span className="where-to-buy-map__availability">{availability}</span>
          {brandSelector ? (
            <WhereToBuyRadioButtonGroup
              brandSelector={brandSelector}
              handlerChangeRadioButtonValue={handlerChangeRadioButtonValue}
              radioButtonValue={radioButtonValue}
            />
          ) : null}
          <div className="where-to-buy-map__input-block">
            <span className="where-to-buy-map__sub-title">{subTitle}</span>
            <div className="where-to-buy-map__input-box">
              <input
                title={searchTitle || 'search'}
                className="where-to-buy-map__input"
                value={inputValue}
                onChange={typeInputValue}
              />
              {inputValue.length ? (
                <button
                  type="button"
                  className="where-to-buy-map__button where-to-buy-map__button--cross"
                  aria-label={crossIconAriaLabel}
                  onClick={handlerRemoveInputValue}
                >
                  <IconCustom icon="cross" className="where-to-buy-map__icon" />
                </button>
              ) : null}
              <button
                type="submit"
                className="where-to-buy-map__button where-to-buy-map__button--search"
                aria-label={searchAriaLabel}
              >
                <IconCustom icon="search" className="where-to-buy-map__icon" />
              </button>
            </div>
          </div>
          <button
            type="submit"
            className="where-to-buy-map__button where-to-buy-map__button--geolocation"
            aria-label={searchAriaLabel}
          >
            <IconCustom icon="geolocation" className="where-to-buy-map__icon" />
            <span className="where-to-buy-map__geolocation-label">{geolocationLabel}</span>
          </button>
        </form>
      </Container>

      <GatsbyImage
        image={{ ...image, mobileImage: mobileImage?.gatsbyImage }}
        alt={altLabel}
        objectFit="cover"
        loading="eager"
        className="where-to-buy-map__image"
        fadeIn={false}
      />
    </div>
  );
};

export default WhereToBuyMap;
