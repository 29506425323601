import React, { FC } from 'react';
import { Link as ScrollLink } from 'react-scroll';

import DisclaimerMessage from 'common/DisclaimerMessage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import Button from 'common/Button';
import { textWithOutSpaces } from 'utils/stringUtils/stringUtils';

import { IPropsWhereToBuyTitle } from './models';
import './WhereToBuyTitle.scss';

const WhereToBuyTitle: FC<IPropsWhereToBuyTitle> = ({ headerTitle, isDesktop }) => {
  const {
    properties: { availability, subtitle, title, button },
  } = headerTitle[0];

  const settings = {
    to: button?.[0]?.properties?.link?.[0]?.queryString || '',
    duration: 500,
    spy: true,
    smooth: true,
    offset: -120,
  };

  return (
    <div data-test="WhereToBuyTitle" className="where-to-buy__wrapper">
      <h1 className="where-to-buy__title">{title}</h1>
      {availability ? (
        <DisclaimerMessage
          content={availability}
          isDesktop={isDesktop}
          className="where-to-buy__disclaimer-message"
        />
      ) : null}
      {subtitle ? (
        <DangerouslySetInnerHtml html={subtitle} className="where-to-buy__subtitle" />
      ) : null}
      {button?.length
        ? button.map(({ properties: { ariaLabel, label, link } }) => (
            <div key={label}>
              {link?.[0]?.queryString ? (
                <ScrollLink
                  {...settings}
                  aria-label={ariaLabel}
                  activeClass="active"
                  className="button button--inverse where-to-buy__button"
                  href={textWithOutSpaces(link?.[0]?.queryString)}
                >
                  {label}
                </ScrollLink>
              ) : (
                <Button
                  key={label}
                  variant="inverse"
                  ariaLabel={ariaLabel}
                  link={link?.[0].url}
                  className="where-to-buy__button"
                >
                  {label}
                </Button>
              )}
            </div>
          ))
        : null}
    </div>
  );
};

export default WhereToBuyTitle;
