import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';

import { IPropsWhereToBuyRadioButtonGroup } from './models';
import './WhereToBuyRadioButtonGroup.scss';
import './ShadowWhereToBuyRadioButtonGroup.scss';

const WhereToBuyRadioButtonGroup: FC<IPropsWhereToBuyRadioButtonGroup> = ({
  brandSelector,
  handlerChangeRadioButtonValue,
  radioButtonValue,
}) => {
  if (!brandSelector) {
    return null;
  }

  const {
    properties: { title, brand },
  } = brandSelector;

  return (
    <div data-test="WhereToBuyRadioButtonGroup" className="radio-buttons-block">
      {title ? <span className="radio-buttons-block__title">{title}</span> : null}
      <div className="radio-buttons-block__wrapper">
        {brand?.length
          ? brand.map(({ properties: { title: brandTitle, radioButton, prefix } }) => (
              <div
                className="radio-buttons-block__container"
                key={`${brandTitle}-${title}-${prefix}`}
              >
                {brandTitle ? (
                  <span className="radio-buttons-block__brand-title">{brandTitle}</span>
                ) : null}
                <div className="radio-button-block__box">
                  {radioButton?.length
                    ? radioButton.map(
                        (
                          { properties: { label, altLabel, image, inputValue, ariaLabel } },
                          index
                        ) => (
                          <div className="radio-button-block__input-box" key={inputValue + title}>
                            <input
                              className="radio-button-block__input"
                              type="radio"
                              id={`${brandTitle}-${index}-${title}-${prefix}`}
                              name="brand-prefix"
                              value={inputValue}
                              checked={inputValue === radioButtonValue}
                              onChange={handlerChangeRadioButtonValue(prefix)}
                              role="radiogroup"
                              aria-label={ariaLabel}
                            />
                            <label
                              className="radio-button-block__label"
                              htmlFor={`${brandTitle}-${index}-${title}-${prefix}`}
                            >
                              {image?.fallbackUrl ? (
                                <GatsbyImage
                                  image={image}
                                  alt={altLabel}
                                  className="radio-button-block__image"
                                  objectFit="contain"
                                  objectPosition="0 50%"
                                />
                              ) : (
                                label
                              )}
                            </label>
                          </div>
                        )
                      )
                    : null}
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default WhereToBuyRadioButtonGroup;
