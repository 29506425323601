import React, {
  FC,
  useMemo,
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
  lazy,
  Suspense,
} from 'react';
import { graphql } from 'gatsby';

import { arrayToMatrix } from 'utils/array';

import WhereToBuyStoreCard from 'components/WhereToBuyStoreCard';
import RelatedCarousel from 'components/RelatedCarousel';
import WhereToBuyRadioButtonGroup from 'components/WhereToBuyRadioButtonGroup';
import ScrollElement from 'components/ScrollElement';
import Container from 'layout/Container';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { useRedirectionPopup } from 'components/RedirectionPopup';

import Helpers from './heplers';
import { IPropsWhereToBuyStoresList } from './models';
import './WhereToBuyStoresList.scss';
import './ShadowWhereToBuyStoresList.scss';

export const RedirectionPopupLoadable = lazy(() => import('components/RedirectionPopup'));
const renderLoader = () => null;

const WhereToBuyStoresList: FC<IPropsWhereToBuyStoresList> = ({
  storesList,
  isDesktop,
  windowWidth,
  ariaLabelPrev,
  ariaLabelNext,
  brandSelector,
  hideStoreListRadioButtons,
  bottomDisclaimer,
  redirectionPopup,
  closeAriaLabel,
  isMainHeaderExists,
}) => {
  const { handleOpenPopup, handlePopupVisibility, isVisibleRedirectionPopup, redirectionData } =
    useRedirectionPopup();

  const {
    properties: { title, subTitle, storeCard, anchorID },
  } = storesList[0];

  const [cardRadioButtonValue, setCardRadioButtonValue] = useState<string | null>(null);
  const [cardPrefix, setCardPrefix] = useState<string>('');

  const filteredSlides = useMemo(
    () =>
      storeCard.filter(({ properties: { cardPrefix: cardPrefixProperties } }) =>
        cardPrefix.includes(cardPrefixProperties)
      ),
    [storeCard, cardPrefix]
  );

  const slides = useMemo(
    () => arrayToMatrix(brandSelector ? filteredSlides : storeCard, 4),
    [filteredSlides]
  );

  const slidesAmount = useCallback(
    () => Helpers.slidesAmountFunc(Boolean(isDesktop), Number(windowWidth)),
    [isDesktop, windowWidth]
  );

  const showPagination = useMemo(() => slidesAmount() < slides.length, [slides, slidesAmount]);

  useEffect(() => {
    if (brandSelector) {
      brandSelector?.properties.brand.forEach(
        ({ properties: { defaultValue, radioButton, prefix } }) => {
          if (defaultValue === '1' && radioButton?.[0].properties?.inputValue) {
            setCardRadioButtonValue(radioButton[0].properties.inputValue);
            setCardPrefix(prefix);
          }
        }
      );
    }
  }, []);

  const handlerChangeCardRadioButtonValue = useCallback(
    (prefix: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setCardRadioButtonValue(e.target.value);
      setCardPrefix(prefix);
    },
    []
  );

  const HeaderTag = isMainHeaderExists ? 'h2' : 'h1';

  return (
    <ScrollElement name={anchorID || ''}>
      <div data-test="WhereToBuyStoresList" className="stores-list__wrapper">
        {title ? <HeaderTag className="stores-list__title">{title}</HeaderTag> : null}
        {subTitle ? <h3 className="stores-list__sub-title">{subTitle}</h3> : null}
        {hideStoreListRadioButtons === '0' ? (
          <>
            {brandSelector ? (
              <Container className="stores-list__radio-buttons">
                <WhereToBuyRadioButtonGroup
                  brandSelector={brandSelector}
                  radioButtonValue={cardRadioButtonValue}
                  handlerChangeRadioButtonValue={handlerChangeCardRadioButtonValue}
                />
              </Container>
            ) : null}
          </>
        ) : null}

        <RelatedCarousel
          slidesPerView={slidesAmount()}
          ariaLabelPrev={ariaLabelPrev}
          ariaLabelNext={ariaLabelNext}
          isDesktop={Boolean(isDesktop)}
          isShowPagination={showPagination}
          isShowNavigation={showPagination}
          loop={isDesktop ? slides.length > 4 : slides.length > 1}
        >
          {slides.map((elements, index) => (
            <WhereToBuyStoreCard
              {...{ elements }}
              key={`related-carousel-where-to-buy-${index.toString()}`}
              onClickHandler={redirectionPopup?.length ? handleOpenPopup : undefined}
            />
          ))}
        </RelatedCarousel>

        {bottomDisclaimer ? (
          <DangerouslySetInnerHtml
            html={bottomDisclaimer}
            className="bottom-disclaimer container"
          />
        ) : null}

        {redirectionPopup?.length && isVisibleRedirectionPopup ? (
          <Suspense fallback={renderLoader()}>
            <RedirectionPopupLoadable
              redirectionPopup={redirectionPopup}
              handlePopupVisibility={handlePopupVisibility}
              closeAriaLabel={closeAriaLabel}
              redirectionData={redirectionData}
            />
          </Suspense>
        ) : null}
      </div>
    </ScrollElement>
  );
};

export const query = graphql`
  fragment FragmentStoresList on TStoresList {
    properties {
      subTitle
      title
      storeCard {
        properties {
          cardPrefix
          altLabel
          link {
            name
            url
            target
            queryString
          }
          image {
            ...FragmentGatsbyImage
          }
        }
      }
      ctaButton {
        ...FragmentButton
      }
      anchorID
    }
  }
`;

export default WhereToBuyStoresList;
