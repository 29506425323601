import React, { FC } from 'react';

import StoreCard from 'components/StoreCard';

import { IPropsWhereToBuyStoreCard } from './models';
import './WhereToBuyStoreCard.scss';

const WhereToBuyStoreCard: FC<IPropsWhereToBuyStoreCard> = ({ elements, onClickHandler }) => (
  <div data-test="WhereToBuyStoreCard" className="store-card__wrapper">
    {elements.map(({ properties: { image, altLabel, link, cardPrefix } }, idx) => (
      <StoreCard
        key={`${link[0].url}-${idx.toString()}`}
        image={image}
        altLabel={altLabel}
        link={link}
        cardPrefix={cardPrefix}
        onClickHandler={onClickHandler}
      />
    ))}
  </div>
);

export default WhereToBuyStoreCard;
