import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout, Container } from 'layout';
import useScreenRecognition from 'hooks/useScreenRecognition';
import BreadCrumbs from 'common/BreadCrumbs';
import WhereToBuyTitle from 'components/WhereToBuyTitle';
import WhereTobuyStoresList from 'components/WhereToBuyStoresList';
import WhereToBuyMap from 'components/WhereToBuyMap';

import { IPropsWhereToBuyPage } from './models';
import './WhereToBuy.scss';
import './ShadowWhereToBuy.scss';

const WhereToBuyPage: FC<IPropsWhereToBuyPage> = ({
  data: { pageData },
  pageContext: { breadcrumbs },
}) => {
  const {
    defaultCompositions: {
      siteSettings: { ariaLabelPrev, ariaLabelNext, searchTitle },
    },
    defaultCompositions,
    seo,
    headerTitle,
    storesList,
    map,
    brandSelector,
    hideStoreListRadioButtons,
    bottomDisclaimer,
    redirectionPopup,
  } = pageData;
  const { isDesktop, windowWidth } = useScreenRecognition();

  const isMainHeaderExists = Boolean(headerTitle?.[0]?.properties);

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      seo={seo}
      className="where-to-buy-page-layout"
    >
      <Container>
        <BreadCrumbs breadcrumbs={breadcrumbs} />
        {isMainHeaderExists ? (
          <WhereToBuyTitle headerTitle={headerTitle} isDesktop={false} />
        ) : null}
      </Container>
      {map?.length ? (
        <WhereToBuyMap
          headerTitle={headerTitle}
          map={map}
          searchTitle={searchTitle}
          brandSelector={brandSelector?.[0]}
          isDesktop={Boolean(isDesktop)}
        />
      ) : null}
      <WhereTobuyStoresList
        brandSelector={brandSelector?.[1]}
        storesList={storesList}
        isDesktop={isDesktop}
        windowWidth={windowWidth}
        ariaLabelPrev={ariaLabelPrev}
        ariaLabelNext={ariaLabelNext}
        hideStoreListRadioButtons={hideStoreListRadioButtons}
        bottomDisclaimer={bottomDisclaimer}
        redirectionPopup={redirectionPopup}
        closeAriaLabel={defaultCompositions.siteSettings.closeAriaLabel}
        isMainHeaderExists={isMainHeaderExists}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($link: String!) {
    pageData: whereToBuy(link: { eq: $link }) {
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      headerTitle {
        ...FragmentHeaderTitle
      }
      hideStoreListRadioButtons
      bottomDisclaimer
      brandSelector {
        properties {
          title
          brand {
            properties {
              defaultValue
              title
              radioButton {
                properties {
                  label
                  image {
                    ...FragmentGatsbyProps
                    gatsbyImage {
                      childImageSharp {
                        fluid(maxWidth: 133, quality: 35) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  altLabel
                  inputValue
                  ariaLabel
                }
              }
              prefix
            }
          }
        }
      }
      storesList {
        ...FragmentStoresList
      }
      map {
        properties {
          altLabel
          geolocationLabel
          subTitle
          prefix
          title
          mapLink
          searchAriaLabel
          crossIconAriaLabel
          image {
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          mobileImage: image {
            fallbackUrl
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 375, quality: 30) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      redirectionPopup {
        ...FragmentRedirectionPopup
      }
    }
  }
`;

export default WhereToBuyPage;
