import { useCallback, useState } from 'react';
import { IRedirectionData, IUseRedirectionPopupResult } from './model';

const DefaultRedirectionData = { link: '', target: null };

export const useRedirectionPopup = (): IUseRedirectionPopupResult => {
  const [isVisibleRedirectionPopup, setVisibleRedirectionPopup] = useState(false);
  const [redirectionData, setRedirectionData] = useState<IRedirectionData>(DefaultRedirectionData);

  const handleOpenPopup = useCallback(
    ({ link, target }: IRedirectionData) =>
      (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setVisibleRedirectionPopup((oldValue: boolean) => !oldValue);
        setRedirectionData({ link, target });
      },
    []
  );

  const handlePopupVisibility = useCallback(() => {
    setVisibleRedirectionPopup((oldValue: boolean) => !oldValue);
    setRedirectionData(DefaultRedirectionData);
  }, []);

  return { handleOpenPopup, handlePopupVisibility, isVisibleRedirectionPopup, redirectionData };
};
